import type { FC, ReactNode } from 'react';
import React, { useCallback } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { CONFLUENCE_TRIAL_LENGTH_14 } from './constants';
import { PERSISTENT_PRETRIAL_BANNERS } from './computePersistentUpgradeBannerCadence';
import { useUpflowModal } from './useUpflowModal';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerLink = styled.a({
	color: token('color.text.inverse', N0),
	textDecoration: 'underline',
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover, :visited': {
		color: token('color.text.inverse', N0),
	},
});

const messages = defineMessages({
	preTrialDay0: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-0',
		defaultMessage: 'Does your team need more from Confluence? {preTrialDayXLink}',
		description:
			"Pre-trial day 0 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-0-link'",
	},
	preTrialDay0Link: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-0-link',
		defaultMessage: 'Start a free trial of the Standard plan.',
		description: 'Banner upgrade link for pre trial day 0',
	},
	preTrialDay7: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-7',
		defaultMessage: 'Set page and space permissions. {preTrialDayXLink}',
		description:
			"Pre-trial day 7 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-7-link'",
	},
	preTrialDay7Link: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-7-link',
		defaultMessage:
			'Try it in a {trialLength, plural, one {#-day} other {#-day}} trial of Confluence Standard.',
		description: 'Banner upgrade link for pre trial day 7',
	},
	preTrialDay21: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-21',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"You're eligible for a free {trialLength, plural, one {#-day} other {#-day}} trial of our Confluence Standard plan. {preTrialDayXLink}",
		description:
			"Pre-trial day 21 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-21-link'",
	},
	preTrialDay21Link: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-21-link',
		defaultMessage: 'Start now!',
		description: 'Banner upgrade link for pre trial day 21',
	},
	preTrialDay42: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-42',
		defaultMessage:
			'Unlock more features with a free {trialLength, plural, one {#-day} other {#-day}} {preTrialDayXLink}.',
		description:
			"Pre-trial day 42 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-42-link'",
	},
	preTrialDay42Link: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-42-link',
		defaultMessage: 'trial of Confluence Standard plan',
		description: 'Banner upgrade link for pre trial day 42',
	},
});

const experimentMessages = defineMessages({
	preTrialDay0: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-0',
		defaultMessage: 'Looking to get the most out of Confluence? {preTrialDayXLink}',
		description:
			"Pre-trial day 0 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-0-link'",
	},
	preTrialDay0Link: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-0-link',
		defaultMessage: 'Start a free trial of Standard or Premium.',
		description: 'Banner upgrade link for pre trial day 0',
	},
	preTrialDay7: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-7',
		defaultMessage: 'Set page and space permissions. {preTrialDayXLink}',
		description:
			"Pre-trial day 7 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-7-link'",
	},
	preTrialDay7Link: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-7-link',
		defaultMessage:
			'Try it in a {trialLength, plural, one {#-day} other {#-day}} trial of Confluence Standard or Premium.',
		description: 'Banner upgrade link for pre trial day 7',
	},
	preTrialDay21: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-21',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"You're eligible for a free {trialLength, plural, one {#-day} other {#-day}} trial of our Confluence Standard or Premium plan. {preTrialDayXLink}",
		description:
			"Pre-trial day 21 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-21-link'",
	},
	preTrialDay21Link: {
		id: 'persistent-upgrade.pre-trial-banner-text.pre-trial-day-21-link',
		defaultMessage: 'Start now!',
		description: 'Banner upgrade link for pre trial day 21',
	},
	preTrialDay42: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-42',
		defaultMessage:
			'Unlock more features with a free {trialLength, plural, one {#-day} other {#-day}} {preTrialDayXLink}.',
		description:
			"Pre-trial day 42 text displayed in a banner at the top of the screen encouraging upgrade, where preTrialDayXLink is a link whose text translated via the ID 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-42-link'",
	},
	preTrialDay42Link: {
		id: 'persistent-upgrade.trial-plan-comparison.pre-trial-banner-text.pre-trial-day-42-link',
		defaultMessage: 'trial of Confluence Standard or Premium plan',
		description: 'Banner upgrade link for pre trial day 42',
	},
});

const PreTrialBannerMessageNames = [
	'preTrialDay0',
	'preTrialDay7',
	'preTrialDay21',
	'preTrialDay42',
];
const PreTrialBannerLinkNames = [
	'preTrialDay0Link',
	'preTrialDay7Link',
	'preTrialDay21Link',
	'preTrialDay42Link',
];

export type PreTrialBannerMessageProps = {
	cadenceIndex: number;
};

export const PreTrialBannerMessage: FC<PreTrialBannerMessageProps> = ({ cadenceIndex }) => {
	const { formatMessage } = useIntl();
	const { isTrialPlanComparisonExperiment, openUpflowModal } = useUpflowModal(
		PERSISTENT_PRETRIAL_BANNERS[cadenceIndex],
	);
	const trialLength = CONFLUENCE_TRIAL_LENGTH_14;
	const bannerMessages = isTrialPlanComparisonExperiment ? experimentMessages : messages;

	// Empty href and target as onClick event will handle upflow SPA modal trigger
	const getBannerLink = useCallback((): ReactNode => {
		return (
			<BannerLink data-testid="pre-trial-banner-link" onClick={openUpflowModal}>
				{formatMessage(bannerMessages[PreTrialBannerLinkNames[cadenceIndex]], {
					trialLength,
				})}
			</BannerLink>
		);
	}, [cadenceIndex, formatMessage, trialLength, openUpflowModal, bannerMessages]);

	return (
		<FormattedMessage
			{...bannerMessages[PreTrialBannerMessageNames[cadenceIndex]]}
			values={{
				trialLength,
				preTrialDayXLink: getBannerLink(),
			}}
		/>
	);
};
